<template>
  <div class="live">

    <van-nav-bar :title="titleList[tab]">
      <template #left>
        <van-icon :size="24" @click="revert()" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <!--唐经理要求去掉-->
    <div class="td_tiket_nav">
      <div class="td_tiket_nav_list " v-if="false" :style="tab != item.value?'font-weight:100':''" v-for="(item, index) in types" :key="index" @click="typeChange(item.value)">
        <span>{{item.name}}</span>
        <div class="td_tiket_nav_color" v-if="tab == item.value"></div>
      </div>
    </div>
    <div class="td-brd"></div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" v-if="videos.length>0">
        <div class="recer_List" @click="toDetail(item)" v-for="(item, index) in videos" :key="index">
          <van-image :src="item.cover" class="recer_List_bg">
            <template v-slot:error>加载失败</template>
          </van-image>
          <img class="recer_List_audo" src="./img/audio.png" alt="">
          <div class="recer_List_item">
            <div class="recer_List_item_left">{{item.name}}</div>
            <div class="recer_List_item_right">{{item.playNumber==null?0:item.playNumber}}人在看</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <div class="no-data-box" v-if="videos.length==0">
      <img src="./img/img_no_data.png" />
    </div>
  </div>
</template>

<script src="./live.js">

</script>

<style scoped src="./live.css">
</style>
